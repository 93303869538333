<template>
  <div v-if="info.name">
    <div class="subheader pb-5 subheader-transparent" id="kt_subheader">
      <div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap py-0">
        <div class="d-flex align-items-center flex-wrap mr-1">
          <div class="d-flex align-items-baseline flex-wrap mr-5">
            <!-- <h5 class="text-dark font-weight-bolder my-1 mr-5 text-capitalize">{{ info.name }}</h5> -->
            <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bolder p-0 my-2 font-size-sm">
              <li class="breadcrumb-item">
                <router-link
                    to="/events"
                    v-slot="{ href, navigate }"
                >
                  <a :href="href" @click="navigate" class="text-muted">{{ $t('event.title') }}</a>
                </router-link>
              </li>
              <li class="breadcrumb-item">
                <span class="text-muted text-capitalize">{{ info.name }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loaded" class="container py-0">
      <div class="row">
      <!--begin::Aside-->
        <div class="col-lg-4" id="kt_profile_aside">
          <!--begin::Profile Card-->
          <div class="card card-custom gutter-b">
            <!--begin::Body-->
            <div class="card-body">
              <span class="font-size-h4 font-weight-bolder text-capitalize">{{ info.name }}</span>

              <div class="d-flex flex-wrap mt-2 mb-5">
                <div class="mr-lg-8 mr-5 mb-lg-0">
                  <div class="text-muted font-weight-bolder mb-2">{{ $t('event.Topics') }}</div>
                  <div class="label font-weight-bold label-inline label-light-success mr-2 mb-2" v-for="item in info.topics" :key="item.id">{{ item.name }}</div>
                </div>
              </div>

              <div class="d-flex flex-wrap mt-2 mb-5">
                <div class="mr-lg-8 mr-5 mb-lg-0">
                  <div class="text-muted font-weight-bolder mb-2">{{ $t('event.Event_type') }}</div>
                  <div>
                    <h6 class="font-weight-bolder">{{ info.type == 1 ? types[0].name : types[1].name }}</h6>
                  </div>
                </div>
              </div>

              <div class="d-flex flex-wrap mt-2 mb-5">
                <div class="mr-lg-8 mr-5 mb-lg-0">
                  <div class="text-muted font-weight-bolder mb-2">{{ $t('event.Organizer') }}</div>
                  <div>
                    <h6 class="font-weight-bolder text-capitalize">{{ info.organizer }}</h6>
                  </div>
                </div>
              </div>

              <div v-if="locations.length > 0" class="d-flex flex-wrap mt-2 mb-5">
                <div class="mr-lg-8 mr-5 mb-lg-0">
                  <div class="text-muted font-weight-bolder mb-2">{{ $t('event.Location') }}</div>
                  <div>
                    <h6 class="font-weight-bolder text-capitalize">
                      {{ locations.filter(location => location.id == info.location_id)[0].name }}
                    </h6>
                  </div>
                </div>
              </div>

              <div class="d-flex flex-wrap mt-2 mb-5" v-if="info.hall">
                <div class="mr-lg-8 mr-5 mb-lg-0">
                  <div class="text-muted font-weight-bolder mb-2">{{ $t('event.Hall') }}</div>
                  <div>
                    <h6 class="font-weight-bolder text-capitalize">{{ info.hall }}</h6>
                  </div>
                </div>
              </div>

              <div class="d-flex flex-wrap mt-2 mb-5">
                <div class="mb-lg-0 w-100">
                  <div class="text-muted font-weight-bolder mb-2">{{ $t('event.Dates') }}</div>
                  <div class="d-flex justify-content-between" v-for="(interval, index) in info.intervals" :key="index">
                    <h6 class="font-weight-bolder text-capitalize">
                      {{ interval.ts_start | formatDate }}
                      {{ new Date(interval.ts_start) | formatDateTime }}
                    </h6>
                    <span> - </span>
                    <h6 class="font-weight-bolder text-capitalize">
                      {{ interval.ts_end | formatDate }}
                      {{ new Date(interval.ts_end) | formatDateTime }}
                    </h6>
                  </div>
                </div>
              </div>

              <a class="btn btn-block btn-light-success" v-if="!info.is_user_registered" @click="goOn(info)">
                {{ $t('event.Registration_On_Event') }}
              </a>
              <a class="btn btn-block btn-light-danger" v-else @click="showDialogOffModal(info)">
                {{ $t('event.Registration_Off_Event') }}
              </a>

            </div>
            <!--end::Body-->
          </div>

        </div>
      <!--end::Aside-->

      <!--begin::Content-->
        <div class="col-lg-8">
          <div class="card gutter-b">
            <img class="view-image" src="@/assets/noimage.png" v-if="info.cover == null" height="350px">
            <img class="view-image" height="350px" v-else :src="info.cover" alt="">
          </div>

          <div class="card gutter-b">

            <div class="card-header">
              <div class="card-title m-0">
                <h4 class="card-label font-weight-bolder text-dark m-0">{{ $t('event.Description') }}</h4>
              </div>
            </div>
            <div class="card-body pt-5">
              <p class="m-0" v-html="$options.filters.formatNameLink($options.filters.formatTextLink(info.description))"></p>
            </div>

          </div>
        </div>
      <!--end::Content-->

      <CoreConfirm :title="$t('event.Registration_Off_Event')"
                  :closeButtonTitle="$t('event.Close')"
                  :okButtonTitle="$t('event.Registration_Off_Confirm_Buttom')"
                  :onOk="onRegistrationOff"
                  icon="bun"
                  :show.sync="alertRegistrationOffModal">
        <template>
          <div class="font-weight-bolder">{{ $t('event.Registration_Off_Event_Body') }}</div>
          <div class="text-muted">{{ $t('event.Registration_Off_Event_Body_Desc') }}</div>
        </template>
      </CoreConfirm>

      </div>
    </div>

    <CoreAlert :title="$t('event.Registration_On_Event')"
                :closeButtonTitle="$t('event.Close')"
                closeButtonColor="light-primary"
                icon="ok"
                :show.sync="alertRegistrationOnModal">
        <template>
          <div class="font-weight-bolder">{{ $t('event.Registration_On_Event_Body') }}</div>
          <div class="text-muted">{{ $t('event.Registration_On_Event_Body_Desc') }}</div>
          <span class="text-muted text-capitalize">«{{ itemTemp.name }}»</span>
        </template>
      </CoreAlert>
  </div>
</template>


<script>
import Nl2br from 'vue-nl2br'
import {mapMutations, mapGetters} from 'vuex'
import api from '@/plugins/api'
import CoreConfirm from '@/components/core/Confirm';
import CoreAlert from '@/components/core/Alert';
let cookie = require('vue-cookie');

export default {
  components: { Nl2br, CoreConfirm, CoreAlert },
  computed: {
    ...mapGetters({
      info: 'events/info',
      locations: 'events/locations',
      loaded: 'events/loaded'
    })
  },
  data() {
    return {
      alertRegistrationOnModal: false,
      alertRegistrationOffModal: false,
      types: [
        { id: 1, name: this.$t("event.Online_Type") },
        { id: 2, name: this.$t("event.Offline_Type") },
      ],
      itemTemp: {
        name: '',
      },
    }
  },
  mounted() {
    this.fetch()
  },
  watch: {
  },
  methods: {
    goOn(item) {
      let self = this;
      this.itemTemp = item;
      api.post('/v2/coworking-events/' + item.id + '/guest').then(responce => {
        // console.log(responce);
        this.alertRegistrationOnModal = true;
        this.fetch()
      });
    },
    goOff(item) {
      let self = this;
      api.delete('/v2/coworking-events/' + item.id + '/guest').then(responce => {
        // console.log(responce);
        this.fetch()
      });
    },
    fetch() {
      this.$store.dispatch('events/GetInfo', this.$route.params.id)
    },
    showDialogOffModal(item) {
      this.itemTemp = item;
      this.alertRegistrationOffModal = true;
    },
    onRegistrationOff(show) {
      this.goOff(this.itemTemp);
      // console.log('show onRegistrationOff', show);
    },
  }
}
</script>

<style>
.view-image {
  object-fit: cover;
  border-radius: 0.42rem;
}
</style>
